import {
	queryOptions,
	useQuery as useBaseQuery,
	keepPreviousData,
	type UseQueryResult,
	type UseQueryOptions,
} from "@tanstack/react-query";

import { type SortDirection } from "~app/types/application";

import { type GetCompletedResponse, getCompletedLearning } from "./completed-learning.api";
import { type CompletedLearningResponse } from "./completed-learning.schema";

const getQueryKey = (sort: SortDirection = "DESC") => ["completed-learning", { sort }] as const;

const getBaseQueryOptions = <TData = GetCompletedResponse>() =>
	queryOptions<GetCompletedResponse, Error, TData>({
		placeholderData: keepPreviousData,
		queryFn: ({ signal }) => getCompletedLearning("DESC", { signal }),
		queryKey: getQueryKey(),
	});

const useQuery = <TData = GetCompletedResponse>(
	options?: Pick<UseQueryOptions<GetCompletedResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	useBaseQuery<GetCompletedResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});

export {
	useQuery as useCompletedLearningQuery,
	getQueryKey as getCompletedLearningQueryKey,
	getBaseQueryOptions as getCompletedLearningQueryOptions,
	type CompletedLearningResponse,
};
