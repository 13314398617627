import { z } from "zod";

import { PostType } from "~app/enums";

import { sharedResourceSchema } from "../shared.schema";

const resourceCategoriesSchema = z.array(
	sharedResourceSchema.extend({
		type: z.literal(PostType.RESOURCE_CATEGORY).catch(PostType.RESOURCE_CATEGORY),
	}),
);

type ResourceCategoriesResponse = z.infer<typeof resourceCategoriesSchema>;

export { resourceCategoriesSchema, type ResourceCategoriesResponse };
