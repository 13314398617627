/**
 * Sanitizes a string by inserting zero-width spaces around underscores.
 * This helps prevent underscores from being interpreted as formatting commands in systems like Markdown.
 * @param string - The input string to be sanitized.
 * @returns The sanitized string with zero-width spaces inserted around underscores.
 * @example
 * const inputString = "this_is_a_test";
 * const sanitizedString = sanitiseUnderscoreString(inputString); // Output: this\u200B_\u200Btest
 */
export const sanitiseUnderscoreString = (string: string): string => string.replace(/_/g, "\u200B_\u200B");
