import { z } from "zod";

import { PostType } from "~app/enums";

import { baseLearningGroupChild, baseLearningGroupSchema } from "../../shared.schema";

export const missionSchema = baseLearningGroupSchema.extend({
	awardIds: z.array(z.number()).nullable().optional(),
	children: z.array(
		baseLearningGroupChild.extend({
			type: z
				.literal(PostType.ARTICLE)
				.or(z.literal(PostType.AUDIO_VISUAL))
				.or(z.literal(PostType.PDF))
				.or(z.literal(PostType.QUIZ))
				.or(z.literal(PostType.SCORM)),
		}),
	),
	dueDate: z.string().datetime({ offset: true }).nullable(),
	type: z.literal(PostType.MISSION),
});

export type MissionResponse = z.infer<typeof missionSchema>;
