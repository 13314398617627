import { z } from "zod";

import { cacheableMediaUrlSchema } from "~app/lib/schemas/cacheable-media-url";

export const leaderboardPersonSchema = z.object({
	avatarUrl: cacheableMediaUrlSchema(true).nullable(),
	id: z.number().or(z.string()).nullable(),
	isCurrentUser: z.boolean().catch(false),
	name: z.string(),
	points: z.number().or(z.string()),
	position: z.number().or(z.string()),
});

export type LeaderboardPerson = z.infer<typeof leaderboardPersonSchema>;

export const leaderboardResponseSchema = z.array(leaderboardPersonSchema);

export type LeaderboardResponse = z.infer<typeof leaderboardResponseSchema>;
