import { type QueryClient } from "@tanstack/react-query";

import { prefetchActivitiesData } from "~app/features/activities/data-prefetch";
import { prefetchAwardsData } from "~app/features/awards/data-prefetch";
import { prefetchBookmarksData } from "~app/features/bookmarks/data-prefetch";
import { prefetchInterestsData } from "~app/features/interests/data-prefetch";
import { prefetchLearningData } from "~app/features/learning/data-prefetch";
import { prefetchResourceData } from "~app/features/resources/data-prefetch";

export const prefetchApiData = (queryClient: QueryClient) => {
	prefetchActivitiesData(queryClient);
	prefetchLearningData(queryClient);
	prefetchAwardsData(queryClient);
	prefetchBookmarksData(queryClient);
	prefetchInterestsData(queryClient);
	prefetchResourceData(queryClient);
};
