import { applicationContext } from "~app/features/application/context";
import { logError } from "~app/lib/external/logging";
import { generateHexUuid } from "~app/utilities/generate-hex-uuid";

import { postAnalyticsEvent } from "./api/event";
import { getPageviewId } from "./page-view";
import { type AnalyticsEvent } from "./types";

export const trackAnalyticsEvent = async (event: AnalyticsEvent) => {
	try {
		const pageview = getPageviewId();
		const timestamp = Date.now();
		const id = generateHexUuid();
		const connectionType = applicationContext.connection.connectionType;
		const embeddedPlatform = "none";
		await postAnalyticsEvent({ ...event, connectionType, embeddedPlatform, id, pageview, timestamp });
	} catch (error) {
		logError(error, { extra: { debugInfo: "Analytics - page view" } });
	}
};
