import { z } from "zod";

import { PostType } from "~app/enums";
import { contentBlockSchema } from "~app/features/learning/api/stage/stage.schema";
import { featuredMediaSchema } from "~app/lib/schemas/featured-media";
import { titleSchema } from "~app/lib/schemas/title";

const resourceSchema = z.object({
	commentsAllowed: z.boolean().catch(true),
	content: z.array(contentBlockSchema),
	featuredMedia: featuredMediaSchema.nullable().optional(),
	id: z.number(),
	title: titleSchema,
	type: z.literal(PostType.RESOURCE),
});

type ResourceResponse = z.infer<typeof resourceSchema>;

export { resourceSchema, type ResourceResponse };
