import { type LeaderboardKey } from "~app/enums";
import { buildUrl } from "~app/features/application/utilities";
import { wretch } from "~app/lib/external/wretch";

import { leaderboardResponseSchema } from "./leaderboard.schema";

const getUrl = () => buildUrl("activities/leaderboard", 1);

const getLeaderboard = (leaderboardKey: LeaderboardKey) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.query({ leaderboardKey })
		.get(getUrl())
		.json(leaderboardResponseSchema.parseAsync);

type GetLeaderboardResponse = Awaited<ReturnType<typeof getLeaderboard>>;

export { getLeaderboard, getUrl as getLeaderboardUrl, type GetLeaderboardResponse };
