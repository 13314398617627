import { getCookie } from "@evotix/napier-ui-common-native-bridge/utilities";

import { TOKEN_COOKIE_NAME } from "~app/features/application/constants";

/**
 * Checks if the authentication cookie exists.
 * @returns True if the authentication cookie exists, false otherwise.
 * @example
 * // Returns true if the authentication cookie exists
 * const result = hasAuthenticationCookie();
 */
export const hasAuthenticationCookie = async () =>
	Boolean(await getCookie(TOKEN_COOKIE_NAME)) && (await getCookie(TOKEN_COOKIE_NAME)) !== "deleted";
