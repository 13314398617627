import { buildUrl } from "~app/features/application/utilities";
import { wretch } from "~app/lib/external/wretch";
import { type AbortableRequestOptions } from "~app/types/application";

import { type ResourceResponse, resourceSchema } from "./resource.schema";

const getUrl = (id: number) => buildUrl(`resource/${id}`, 1);

const getResource = (id: number, { signal }: AbortableRequestOptions): Promise<ResourceResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.get(getUrl(id))
		.json(resourceSchema.parseAsync);

type GetResourceResponse = Awaited<ReturnType<typeof getResource>>;

export { getResource, getUrl, type GetResourceResponse };
