import { useCallback, useEffect } from "react";

import { CHANNEL_NAME } from "./constants";

interface SignInMessage {
	type: "sign-in";
}

interface SignOutMessage {
	type: "sign-out";
}

interface BroadcastMessageMap {
	"sign-in": SignInMessage;
	"sign-out": SignOutMessage;
}

export type BroadcastMessage = SignInMessage | SignOutMessage;

const getBroadcastChannel = () => new BroadcastChannel(CHANNEL_NAME);

export const useBroadcastChannel = () => {
	const broadcastMessage = useCallback((msg: BroadcastMessage) => getBroadcastChannel().postMessage(msg), []);

	return { broadcastMessage };
};

export const useBroadcastChannelListener = <TType extends BroadcastMessage["type"]>(
	type: TType,
	callback: (event: MessageEvent<BroadcastMessageMap[TType]>) => void,
) => {
	useEffect(() => {
		const controller = new AbortController();
		const channel = getBroadcastChannel();

		channel.addEventListener(
			"message",
			(event: MessageEvent<BroadcastMessageMap[TType]>) => {
				if (event.data.type === type) callback(event);
			},
			{ signal: controller.signal },
		);

		return () => {
			controller.abort();
			channel.close();
		};
	}, [callback, type]);
};
