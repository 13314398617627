import { buildUrl } from "~app/features/application/utilities";
import { wretch } from "~app/lib/external/wretch";
import { type AbortableRequestOptions } from "~app/types/application";

import { type ResourceCategoriesResponse, resourceCategoriesSchema } from "./categories.schema";

const getUrl = () => buildUrl("resource-category", 1);

const getResourceCategories = ({ signal }: AbortableRequestOptions): Promise<ResourceCategoriesResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl())
		.json(resourceCategoriesSchema.parseAsync);

type GetResourceCategoriesResponse = Awaited<ReturnType<typeof getResourceCategories>>;

export { getResourceCategories, getUrl, type GetResourceCategoriesResponse };
