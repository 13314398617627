import { z } from "zod";

import { AuthType } from "~app/enums";
import { cacheableMediaUrlSchema } from "~app/lib/schemas/cacheable-media-url";

export const profileSchema = z.object({
	acceptedPrivacyPolicy: z.boolean().nullable().catch(null),
	acceptedTermsConditions: z.boolean().nullable().catch(null),
	authType: z.nativeEnum(AuthType).catch(AuthType.INTERNAL),
	avatarUrl: cacheableMediaUrlSchema(true).nullable(),
	firstName: z.string().nullable(),
	id: z.number(),
	interests: z.array(z.number()).nullable().catch(null),
	lastName: z.string().nullable(),
	name: z.string(),
	permissions: z
		.object({
			canEditOwnComments: z.boolean().catch(false),
			canSubmitContent: z.boolean().catch(false),
		})
		.catch({ canEditOwnComments: false, canSubmitContent: false }),
});

export type ProfileResponse = z.infer<typeof profileSchema>;
