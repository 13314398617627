import { buildUrl } from "~app/features/application/utilities";
import { wretch } from "~app/lib/external/wretch";
import { type AbortableRequestOptions } from "~app/types/application";

import { pathwaysSchema } from "./pathways.schema";

const getUrl = () => buildUrl("pathways", 1);

const getPathways = ({ signal }: AbortableRequestOptions) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl())
		.json(pathwaysSchema.parseAsync);

type GetPathwaysResponse = Awaited<ReturnType<typeof getPathways>>;

export { getPathways, getUrl, type GetPathwaysResponse };
