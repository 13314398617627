import { type Wretch, type WretchResponseChain, type WretchErrorCallback, type WretchAddon } from "wretch";

export interface AbortWretch {
	/**
	 * Associates a custom controller with the request.
	 *
	 * Useful when you need to use
	 * your own AbortController, otherwise wretch will create a new controller itself.
	 *
	 * ```js
	 * const controller = new AbortController()
	 *
	 * // Associates the same controller with multiple requests
	 * wretch("url1")
	 * .addon(abortAddon())
	 * .signal(controller.signal)
	 * .get()
	 * .json()
	 *
	 * wretch("url2")
	 * .addon(abortAddon())
	 * .signal(controller.signal)
	 * .get()
	 * .json()
	 *
	 * // Aborts both requests
	 * controller.abort()
	 * ```
	 * @param controller - An instance of AbortController
	 */
	signal: <T extends AbortWretch, C, R>(this: T & Wretch<T, C, R>, signal: AbortSignal) => this;
}

export interface AbortResolver {
	/**
	 * Catches an AbortError and performs a callback.
	 */
	onAbort: <T, C extends AbortResolver, R>(
		this: C & WretchResponseChain<T, C, R>,
		cb: WretchErrorCallback<T, C, R>,
	) => this;
}

export const abortAddon: () => WretchAddon<AbortWretch, AbortResolver> = () => {
	return {
		resolver: {
			onAbort(cb) {
				return this.error("AbortError", cb);
			},
		},
		wretch: {
			signal(signal) {
				return { ...this, _options: { ...this._options, signal } };
			},
		},
	};
};
