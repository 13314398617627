import { z } from "zod";

import { decodeEntities } from "~app/utilities/decode-entities";
import { sanitiseUnderscoreString } from "~app/utilities/sanitise-underscore-string";

export const titleSchema = z.string().transform(decodeEntities).transform(sanitiseUnderscoreString).nullable();

export type TitleType = z.infer<typeof titleSchema>;

export type TitleProp = { title: TitleType };
