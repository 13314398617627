import { buildUrl } from "~app/features/application/utilities";
import { wretch } from "~app/lib/external/wretch";
import { type AbortableRequestOptions } from "~app/types/application";

import { missionsSchema } from "./missions.schema";

const getUrl = () => buildUrl("missions", 1);

const getMissions = ({ signal }: AbortableRequestOptions) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl())
		.json(missionsSchema.parseAsync);

type GetMissionsResponse = Awaited<ReturnType<typeof getMissions>>;

export { getMissions, getUrl, type GetMissionsResponse };
