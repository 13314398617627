import { z } from "zod";

import { PostType } from "~app/enums";
import { cacheableMediaUrlSchema } from "~app/lib/schemas/cacheable-media-url";

const latestResourceSchema = z.object({
	date: z.string().datetime({ offset: true }),
	id: z.number(),
	thumbnail: z
		.object({
			alt: z.string().nullable(),
			title: z.string().nullable(),
			url: cacheableMediaUrlSchema().nullable(),
		})
		.nullable(),
	title: z.string(),
	type: z.literal(PostType.RESOURCE),
});

const latestResourcesSchema = z.array(latestResourceSchema);

type LatestResourcesResponse = z.infer<typeof latestResourcesSchema>;

export { latestResourcesSchema, type LatestResourcesResponse };
