import { buildUrl } from "~app/features/application/utilities";
import { wretch } from "~app/lib/external/wretch";
import { type AbortableRequestOptions } from "~app/types/application";

import { learningSchema } from "./learning.schema";

const getLearningUrl = () => buildUrl("learning", 1);

const getLearning = ({ signal }: AbortableRequestOptions) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getLearningUrl())
		.json(learningSchema.parseAsync);

type GetLearningResponse = Awaited<ReturnType<typeof getLearning>>;

export { getLearning, getLearningUrl, type GetLearningResponse };
