import { z } from "zod";

const stringOrNumberToNumberSchema = z.string().or(z.number()).transform(Number);

const baseLearningItemSchema = z.object({
	id: stringOrNumberToNumberSchema,
});

const learningItemSchema = baseLearningItemSchema.extend({
	children: z.array(stringOrNumberToNumberSchema),
});

export const learningSchema = z.object({
	levels: z.array(learningItemSchema),
	missions: z.array(learningItemSchema),
	pathways: z.array(learningItemSchema.extend({ order: stringOrNumberToNumberSchema })),
	stages: z.array(baseLearningItemSchema),
	thirdParty: z.array(learningItemSchema.extend({ smi: stringOrNumberToNumberSchema })).nullable(),
});

export type LearningResponse = z.infer<typeof learningSchema>;
