import { z } from "zod";

import { PostType } from "~app/enums";
import { cacheableMediaUrlSchema } from "~app/lib/schemas/cacheable-media-url";

import { baseLearningGroupChild } from "../shared.schema";

export const pathwaysSchema = z.array(
	baseLearningGroupChild.extend({
		content_locked: z.boolean().optional().catch(false),
		thumbnailUrl: cacheableMediaUrlSchema(true).nullable().optional(),
		type: z.literal(PostType.PATHWAY),
	}),
);

export type PathwaysResponse = z.infer<typeof pathwaysSchema>;
