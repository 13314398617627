import { hashKey, type QueryKey } from "@tanstack/react-query";

import { queryClient } from "~app/lib/queryClient";

import { getBookmarksQueryKey, type BookmarksResponse } from ".";
import { type Bookmark } from "./bookmarks.schema";

export const buildBookmarkMap = <TQueries extends [QueryKey, ReturnType<typeof getBookmarkQueriesData>[number][1]][]>(
	queryData: TQueries,
) => {
	const bookmarkMap = new Map<number, Bookmark>();

	queryData
		.map(([_, data]) => data?.items)
		.flat()
		.filter(Boolean)
		.forEach((item) => bookmarkMap.set(item.id, item));

	return bookmarkMap;
};

export const getBookmarkQueriesData = () =>
	queryClient.getQueriesData<BookmarksResponse>({
		predicate: ({ queryHash }) =>
			queryHash === hashKey(getBookmarksQueryKey("ASC")) || queryHash === hashKey(getBookmarksQueryKey("DESC")),
	});
