/**
 * This contains the routes for the learning pages of the application.
 * It also has the routes for the direct mission pages.
 */

import { nprogress } from "@mantine/nprogress";
import { createRoute, lazyRouteComponent, useNavigate } from "@tanstack/react-router";
import { t } from "i18next";

import { getLevelBaseQueryOptions } from "~app/features/learning/api/learning-group/level";
import { getMissionBaseQueryOptions } from "~app/features/learning/api/learning-group/mission";
import { getPathwayBaseQueryOptions } from "~app/features/learning/api/learning-group/pathway";
import { getPathwaysBaseQueryOptions } from "~app/features/learning/api/pathways";
import { getStageBaseQueryOptions } from "~app/features/learning/api/stage";

import { applicationLayout } from "./application";

export const learningRoute = createRoute({
	getParentRoute: () => applicationLayout,
	loader: async ({ context }) => {
		try {
			await context.queryClient.ensureQueryData(getPathwaysBaseQueryOptions());
		} catch {
			/* swallow the error and let the page handle it */
		}
	},
	path: "learning",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
	staticData: {
		analyticsTitle: "Learning",
		crumb: () => t("navigation.learning"),
	},
});

export const learningIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/learning/pages/Root"), "Page"),
	getParentRoute: () => learningRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "/",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

export const pathwayRoute = createRoute({
	getParentRoute: () => learningRoute,
	loader: async ({ params, context }) => {
		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.pathway.loading"),
		};

		try {
			const pathway = await context.queryClient.ensureQueryData(
				getPathwayBaseQueryOptions(parseInt(params.pathwayId)),
			);
			context.breadcrumbs = {
				crumb: () => pathway.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.pathway.failure"),
			};
		}
	},
	path: "$pathwayId",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

export const pathwayIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/learning/pages/pathway"), "Page"),
	getParentRoute: () => pathwayRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

export const levelRoute = createRoute({
	getParentRoute: () => pathwayRoute,
	loader: async ({ params, context }) => {
		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.level.loading"),
		};

		try {
			const level = await context.queryClient.ensureQueryData(getLevelBaseQueryOptions(parseInt(params.levelId)));
			context.breadcrumbs = {
				crumb: () => level.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.level.failure"),
			};
		}
	},

	path: "$levelId",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

export const levelIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/learning/pages/level"), "Page"),
	getParentRoute: () => levelRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

export const missionRoute = createRoute({
	getParentRoute: () => levelRoute,
	loader: async ({ params, context }) => {
		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.mission.loading"),
		};

		try {
			const mission = await context.queryClient.ensureQueryData(
				getMissionBaseQueryOptions(parseInt(params.missionId)),
			);
			context.breadcrumbs = {
				crumb: () => mission.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.mission.failure"),
			};
		}
	},
	path: "$missionId",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

export const missionIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/learning/pages/mission"), "Page"),
	getParentRoute: () => missionRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

export const stageRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => missionRoute,
	loader: async ({ params, context }) => {
		nprogress.cleanup();
		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.stage.loading"),
		};

		try {
			const stage = await context.queryClient.ensureQueryData(getStageBaseQueryOptions(parseInt(params.stageId)));
			context.breadcrumbs = {
				crumb: () => stage.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.stage.failure"),
			};
		}
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: stageRoute.useParams(),
			to: stageRoute.to,
		});

		return null;
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "$stageId",
});

export const stageIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/learning/pages/stage"), "Page"),
	getParentRoute: () => stageRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});

// Route container handler
export const directLevelRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => applicationLayout,
	loader: async ({ context, params }) => {
		nprogress.cleanup();

		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.level.loading"),
		};

		try {
			const level = await context.queryClient.ensureQueryData(getLevelBaseQueryOptions(parseInt(params.levelId)));
			context.breadcrumbs = {
				crumb: () => level.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.level.failure"),
			};
		}
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "level/$levelId",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

// Index route for direct level - to support our nesting structure
export const directLevelIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/learning/pages/level/DirectLevel"), "Page"),
	getParentRoute: () => directLevelRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});

// Mission route for direct level - to support our nesting structure
export const directLevelMissionRoute = createRoute({
	getParentRoute: () => directLevelRoute,
	loader: async ({ params, context }) => {
		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.mission.loading"),
		};

		try {
			const mission = await context.queryClient.ensureQueryData(
				getMissionBaseQueryOptions(parseInt(params.missionId)),
			);
			context.breadcrumbs = {
				crumb: () => mission.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.mission.failure"),
			};
		}
	},
	path: "$missionId",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

// Index route for direct level mission - to support our nesting structure
export const directLevelMissionIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/learning/pages/level/DirectLevelMission"), "Page"),
	getParentRoute: () => directLevelMissionRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});

// Stage route for direct level - to support our nesting structure
export const directLevelStageRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => directLevelMissionRoute,
	loader: async ({ params, context }) => {
		nprogress.cleanup();

		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.stage.loading"),
		};

		try {
			const stage = await context.queryClient.ensureQueryData(getStageBaseQueryOptions(parseInt(params.stageId)));
			context.breadcrumbs = {
				crumb: () => stage.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.stage.failure"),
			};
		}
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: directLevelStageRoute.useParams(),
			to: directLevelStageRoute.to,
		});

		return null;
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "$stageId",
});

export const directLevelStageIndexRoute = createRoute({
	component: lazyRouteComponent(() => import("~app/features/learning/pages/level/DirectLevelStage"), "Page"),
	getParentRoute: () => directLevelStageRoute,
	path: "/",
});

// Route container handler
export const directMissionRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => applicationLayout,
	loader: async ({ context, params }) => {
		nprogress.cleanup();

		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.mission.loading"),
		};

		try {
			const mission = await context.queryClient.ensureQueryData(
				getMissionBaseQueryOptions(parseInt(params.missionId)),
			);
			context.breadcrumbs = {
				crumb: () => mission.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.mission.failure"),
			};
		}
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "mission/$missionId",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

// Index route for mission - to support our nesting structure
export const directMissionIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/learning/pages/mission/DirectMission"), "Page"),
	getParentRoute: () => directMissionRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});

// Stage route for mission - to support our nesting structure
export const directMissionStageRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => directMissionRoute,
	loader: async ({ params, context }) => {
		nprogress.cleanup();

		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.stage.loading"),
		};

		try {
			const stage = await context.queryClient.ensureQueryData(getStageBaseQueryOptions(parseInt(params.stageId)));
			context.breadcrumbs = {
				crumb: () => stage.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.stage.failure"),
			};
		}
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: directMissionStageRoute.useParams(),
			to: directMissionStageRoute.to,
		});

		return null;
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "$stageId",
});

export const directMissionStageIndexRoute = createRoute({
	component: lazyRouteComponent(() => import("~app/features/learning/pages/mission/DirectMissionStage"), "Page"),
	getParentRoute: () => directMissionStageRoute,
	path: "/",
});

// Direct stage route container handler
export const directStageRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => applicationLayout,
	loader: async ({ context, params }) => {
		nprogress.cleanup();
		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.stage.loading"),
		};

		try {
			const stage = await context.queryClient.ensureQueryData(getStageBaseQueryOptions(parseInt(params.stageId)));
			context.breadcrumbs = {
				crumb: () => stage.title,
			};
		} catch {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.stage.failure"),
			};
		}
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: directStageRoute.useParams(),
			to: directStageRoute.to,
		});

		return null;
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "stage/$stageId",
	pendingComponent: lazyRouteComponent(() => import("~app/features/learning/pages/Learning.pending"), "Pending"),
});

// Direct stage route
export const directStageIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~app/features/learning/pages/stage/DirectStage"), "Page"),
	getParentRoute: () => directStageRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});
