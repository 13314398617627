import { type UseQueryOptions, type QueryClient } from "@tanstack/react-query";

import { type PostType } from "~app/enums";

import { getLearningBaseQueryOptions } from "../api/learning";
import { getLevelBaseQueryOptions } from "../api/learning-group/level";
import { getMissionBaseQueryOptions } from "../api/learning-group/mission";
import { getPathwayBaseQueryOptions } from "../api/learning-group/pathway";
import { type LearningGroupResponse } from "../api/learning.schema";
import { getMissionsBaseQueryOptions } from "../api/missions";
import { getPathwaysBaseQueryOptions } from "../api/pathways";
import { getStageBaseQueryOptions, type StageResponse } from "../api/stage";

export const prefetchLearningData = (queryClient: QueryClient) => {
	const prefetchGroupData = <
		TResponseGroup,
		TResponseType extends PostType,
		TData extends { id: number }[] = { id: number }[],
	>(
		data: TData,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Not important here
		queryOptionsGetter: (...args: any[]) => UseQueryOptions<Extract<TResponseGroup, { type: TResponseType }>>,
	) => {
		for (const item of data) {
			queryClient.prefetchQuery(queryOptionsGetter(item.id));
		}
	};

	// This gets all the learning data the user has assigned to them.
	queryClient.ensureQueryData(getLearningBaseQueryOptions()).then((data) => {
		prefetchGroupData<LearningGroupResponse, PostType.PATHWAY>(data.pathways, getPathwayBaseQueryOptions);
		prefetchGroupData<LearningGroupResponse, PostType.LEVEL>(data.levels, getLevelBaseQueryOptions);
		prefetchGroupData<LearningGroupResponse, PostType.MISSION>(
			[...data.missions, ...(data.thirdParty ?? [])],
			getMissionBaseQueryOptions,
		);
		prefetchGroupData<
			StageResponse,
			PostType.ARTICLE | PostType.AUDIO_VISUAL | PostType.PDF | PostType.QUIZ | PostType.SCORM
		>(data.stages, getStageBaseQueryOptions);
	});

	// TODO: These will eventually be removed, once the all learning endpoints have been updated to include the any missing data
	queryClient.prefetchQuery(getPathwaysBaseQueryOptions());
	queryClient.prefetchQuery(getMissionsBaseQueryOptions());
};
