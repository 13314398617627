import {
	type PartialKeyByTranslationGroup,
	type TranslationKey,
	type TranslationKeyByGroupShallow,
} from "~app/types/application";

type PasswordStrengthGroupKey = TranslationKeyByGroupShallow<
	TranslationKey,
	"shared-form.inputs.new-password.passwordStrength"
>;

type PasswordStrengthGroupPartialKey = PartialKeyByTranslationGroup<
	PasswordStrengthGroupKey,
	"shared-form.inputs.new-password.passwordStrength"
>;

export enum PasswordStrength {
	BAD = 0,
	WEAK = 1,
	AVERAGE = 2,
	GOOD = 3,
	GREAT = 4,
}

export const PASSWORD_STRENGTH_TYPE: Record<
	PasswordStrength,
	{
		background: string;
		color: string;
		translationKey: PasswordStrengthGroupPartialKey;
	}
> = {
	[PasswordStrength.BAD]: {
		background: "danger.9",
		color: "white",
		translationKey: "bad",
	},
	[PasswordStrength.WEAK]: {
		background: "danger.8",
		color: "white",
		translationKey: "weak",
	},
	[PasswordStrength.AVERAGE]: {
		background: "warning.3",
		color: "gray.8",
		translationKey: "average",
	},
	[PasswordStrength.GOOD]: {
		background: "info.7",
		color: "white",
		translationKey: "good",
	},
	[PasswordStrength.GREAT]: {
		background: "success.8",
		color: "white",
		translationKey: "great",
	},
};

export const STRENGTH_UNIT = 100 / Object.keys(PASSWORD_STRENGTH_TYPE).length;
