import { setupNativeAnalytics } from "../native/analytics";

import { LAST_ACTIVITY_STORAGE, SESSION_STORAGE } from "./constants";
import { setupPageAnalytics } from "./page-view";
import { getSessionId } from "./utilities";

/**
 * Clears the session ID and last activity timestamp from sessionStorage.
 * @example clearSession() => undefined
 */
export const clearSession = () => {
	sessionStorage.removeItem(SESSION_STORAGE);
	sessionStorage.removeItem(LAST_ACTIVITY_STORAGE);
};

export const startAnalytics = () => {
	setupPageAnalytics();
	setupNativeAnalytics();
};

// Add event listeners to update the session ID on user activity
["click", "keypress"].forEach((event) => {
	window.addEventListener(event, () => {
		getSessionId();
	});
});

export { trackAnalyticsEvent } from "./analytics-event";
