// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- we don't want to typecheck this file as it's gainsight code
// @ts-nocheck
import { isGainsightEnabled } from ".";

export const injectGainsightTag = async (key: string) => {
	if (!isGainsightEnabled()) return;

	(function (n, t, a, e, co) {
		var i = "aptrinsic";
		(n[i] =
			n[i] ||
			function () {
				(n[i].q = n[i].q || []).push(arguments);
			}),
			(n[i].p = e);
		n[i].c = co;
		var r = t.createElement("script");
		(r.async = !0), (r.src = `${a}?a=${e}`);
		var c = t.getElementsByTagName("script")[0];
		c.parentNode.insertBefore(r, c);
	})(window, document, "https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js", key);
};
