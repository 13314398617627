import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	queryOptions,
	useSuspenseQuery,
} from "@tanstack/react-query";

import { LIVE_QUERY_STALE_TIME } from "~app/features/application/constants";

import { getBaseLearningQueryKey } from "../shared.schema";

import { getStage, getUrl, type GetStageResponse } from "./stage.api";
import { type StageResponse, type QuizData, type Question } from "./stage.schema";

const getQueryKey = (id: number) => [...getBaseLearningQueryKey(), "stage", { id }] as const;

const getBaseQueryOptions = <TData = GetStageResponse>(id: number) =>
	queryOptions<GetStageResponse, Error, TData>({
		queryFn: ({ signal }) => getStage(id, { signal }),
		queryKey: getQueryKey(id),
	});

const useStageSuspenseQuery = <TData = GetStageResponse>(
	id: number,
	options?: Pick<UseSuspenseQueryOptions<GetStageResponse, Error, TData>, "select" | "refetchOnWindowFocus">,
): UseSuspenseQueryResult<TData, Error> =>
	useSuspenseQuery<GetStageResponse, Error, TData>({
		...getBaseQueryOptions(id),
		...options,
		staleTime: LIVE_QUERY_STALE_TIME,
	});

export {
	useStageSuspenseQuery,
	getBaseQueryOptions as getStageBaseQueryOptions,
	getQueryKey as getStageQueryKey,
	getUrl as getStageUrl,
	type StageResponse,
	type QuizData,
	type Question,
};
