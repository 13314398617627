import { type Dayjs, dayjs } from "~app/lib/external/day-js";

type DateType = Dayjs | Date | string | undefined | null;

export const getDateSortValue = (first: DateType, second: DateType, latestFirst: boolean = true) => {
	const date1 = latestFirst ? second : first;
	const date2 = latestFirst ? first : second;

	if (!date1 && !date2) return 0;
	if (!date1 && date2) return latestFirst ? -1 : 1;
	if (date1 && !date2) return latestFirst ? 1 : -1;

	return dayjs(date1).diff(date2);
};
