import { z } from "zod";

const dynamicFeatureFlagsSchema = z.record(z.string(), z.boolean());

const featureFlagsSchema = z.object({
	enableGainsight: z.boolean(),
	enableTestLanguage: z.boolean(),
});

const keysSchema = z.object({
	errorReporting: z.string(),
	gainsight: z.string(),
});

export const dynamicConfigSchema = z.object({
	endpointUrl: z.string().url().min(1),
	environment: z.string(),
	featureFlags: featureFlagsSchema.or(dynamicFeatureFlagsSchema),
	keys: keysSchema,
	remoteAssets: z.array(z.string()).catch([]),
});

export type DynamicGlobalConfig = z.infer<typeof dynamicConfigSchema>;

export type DynamicGlobalConfigFeatures = keyof z.infer<typeof featureFlagsSchema> | (string & {});
