import enGBTranslation from "./locales/en-GB.json";
import { type GlobalValidationErrorKey, type ServerErrorKey } from "./types";
import ebGBZod from "./zod/en-GB.json";

export const LANGUAGE_STORAGE_KEY_BASE = "evotix-learn-language:" as const;

export const DEFAULT_LANGUAGE = "en-GB";

export const TEST_LANGUAGE = "pe-PE";

export const DEFAULT_RESOURCE_BUNDLE = {
	[DEFAULT_LANGUAGE]: {
		translation: enGBTranslation,
		zod: ebGBZod,
	},
};

export const LANGUAGE_OPTIONS = [
	"ar-AE",
	"bg-BG",
	"cs-CZ",
	"da-DK",
	"de-DE",
	"el-GR",
	DEFAULT_LANGUAGE,
	"en-US",
	"es-ES",
	"es-US",
	"fi-FI",
	"fr-CA",
	"fr-FR",
	"hi-IN",
	"hr-HR",
	"hu-HU",
	"it-IT",
	"ja-JP",
	"ko-KR",
	"lt-LT",
	"ms-MY",
	"nb-NO",
	"nl-NL",
	"pa-Guru-IN",
	TEST_LANGUAGE,
	"pl-PL",
	"pt-BR",
	"pt-PT",
	"ro-RO",
	"ru-RU",
	"sk-SK",
	"sl-SI",
	"sv-SE",
	"ta",
	"th-TH",
	"uk-UA",
	"vi-VN",
	"zh-Hans",
] as const;

export const TRANSLATION_SERVER_ERROR_KEYS = Object.keys(
	DEFAULT_RESOURCE_BUNDLE[DEFAULT_LANGUAGE]["translation"]["server-error"],
) as [ServerErrorKey];

export const TRANSLATION_GLOBAL_VALIDATION_ERROR_KEYS = Object.keys(
	DEFAULT_RESOURCE_BUNDLE[DEFAULT_LANGUAGE]["translation"]["global"]["validation-error"],
) as [GlobalValidationErrorKey];
