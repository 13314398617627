import { z } from "zod";

import { titleSchema } from "~app/lib/schemas/title";

export const completedLearningSchema = z.object({
	count: z.number(),
	items: z.array(
		z.object({
			completedAt: z.string().datetime({ offset: true }),
			id: z.number(),
			title: titleSchema,
			type: z.string(),
		}),
	),
});

export type CompletedLearningResponse = z.infer<typeof completedLearningSchema>;
