import { useMutation } from "@tanstack/react-query";

import { postSignOut, type PostSignOutResponse } from "./sign-out.api";

const getMutationKey = () => ["auth", "sign-out"] as const;

const useSignOutMutation = () =>
	useMutation<PostSignOutResponse, Error, void, unknown>({
		mutationFn: postSignOut,
		mutationKey: getMutationKey(),
	});

export { useSignOutMutation, getMutationKey as getSignOutMutationKey };
