import { type CustomTypeOptions } from "i18next";

import { type Language } from "~app/translations/types";

type TranslationsResources = CustomTypeOptions["resources"];
type Namespace = keyof TranslationsResources;
type NamespaceTranslationsResource = TranslationsResources[Namespace];

type ImportResponse = Promise<{ default: NamespaceTranslationsResource }>;

const buildResolvedTranslations = async (map: Map<Namespace, ImportResponse>) => {
	await Promise.allSettled(map.values());

	const resolvedMap = new Map<Namespace, NamespaceTranslationsResource>();

	for await (const [namespace, translations] of map.entries()) {
		resolvedMap.set(namespace, (await translations).default);
	}

	return resolvedMap;
};

export const translationResourceLoader = async (locale: Language) => {
	// eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check -- it covers all cases due to the default;
	switch (locale) {
		case "pa-Guru-IN": {
			const translationKeyImportMap = new Map<Namespace, ImportResponse>([
				["translation", import("../../locales/pa-IN.json")],
				["zod", import("../../zod/pa-IN.json")],
			]);

			return buildResolvedTranslations(translationKeyImportMap);
		}
		case "zh-Hans": {
			const translationKeyImportMap = new Map<Namespace, ImportResponse>([
				["translation", import("../../locales/zh-HANS.json")],
				["zod", import("../../zod/zh-HANS.json")],
			]);

			return buildResolvedTranslations(translationKeyImportMap);
		}
		default: {
			const translationKeyImportMap = new Map<Namespace, ImportResponse>([
				/* v8 ignore next -- we ignore this line from test coverage because the coverage fails to pick it up correctly, even though we have 100% coverage */
				["translation", import(`../../locales/${locale}.json`)],
				/* v8 ignore next -- we ignore this line from test coverage because the coverage fails to pick it up correctly, even though we have 100% coverage */
				["zod", import(`../../zod/${locale}.json`)],
			]);

			return buildResolvedTranslations(translationKeyImportMap);
		}
	}
};
