export const AWARDS_TO_DISPLAY = 6;

export const AWARD_MAP = {
	"alert-circle": () => import("~app/assets/images/awards/alert-circle.svg"),
	"alert-diamond": () => import("~app/assets/images/awards/alert-diamond.svg"),
	"alert-triangle": () => import("~app/assets/images/awards/alert-triangle.svg"),
	bandage: () => import("~app/assets/images/awards/bandage.svg"),
	breakage: () => import("~app/assets/images/awards/breakage.svg"),
	"building-fire": () => import("~app/assets/images/awards/building-fire.svg"),
	car: () => import("~app/assets/images/awards/car.svg"),
	"car-accident": () => import("~app/assets/images/awards/car-accident.svg"),
	"car-fault": () => import("~app/assets/images/awards/car-fault.svg"),
	checklist: () => import("~app/assets/images/awards/checklist.svg"),
	chemical: () => import("~app/assets/images/awards/chemical.svg"),
	configuration: () => import("~app/assets/images/awards/configuration.svg"),
	"construction-female": () => import("~app/assets/images/awards/construction-female.svg"),
	"construction-male": () => import("~app/assets/images/awards/construction-male.svg"),
	damage: () => import("~app/assets/images/awards/damage.svg"),
	default: () => import("~app/assets/images/awards/default.svg"),
	deforestation: () => import("~app/assets/images/awards/deforestation.svg"),
	delivery: () => import("~app/assets/images/awards/delivery.svg"),
	ecology: () => import("~app/assets/images/awards/ecology.svg"),
	environment: () => import("~app/assets/images/awards/environment.svg"),
	extinguisher: () => import("~app/assets/images/awards/extinguisher.svg"),
	"fall-hazard": () => import("~app/assets/images/awards/fall-hazard.svg"),
	"find-settings": () => import("~app/assets/images/awards/find-settings.svg"),
	fire: () => import("~app/assets/images/awards/fire.svg"),
	"fire-escape": () => import("~app/assets/images/awards/fire-escape.svg"),
	forklift: () => import("~app/assets/images/awards/forklift.svg"),
	gardening: () => import("~app/assets/images/awards/gardening.svg"),
	gloves: () => import("~app/assets/images/awards/gloves.svg"),
	goggles: () => import("~app/assets/images/awards/goggles.svg"),
	growth: () => import("~app/assets/images/awards/growth.svg"),
	hammer: () => import("~app/assets/images/awards/hammer.svg"),
	hardhat: () => import("~app/assets/images/awards/hardhat.svg"),
	harmful: () => import("~app/assets/images/awards/harmful.svg"),
	hospital: () => import("~app/assets/images/awards/hospital.svg"),
	idea: () => import("~app/assets/images/awards/idea.svg"),
	injured: () => import("~app/assets/images/awards/injured.svg"),
	injury: () => import("~app/assets/images/awards/injured.svg"),
	ladder: () => import("~app/assets/images/awards/ladder.svg"),
	location: () => import("~app/assets/images/awards/location.svg"),
	office: () => import("~app/assets/images/awards/office.svg"),
	poison: () => import("~app/assets/images/awards/poison.svg"),
	pollution: () => import("~app/assets/images/awards/pollution.svg"),
	question: () => import("~app/assets/images/awards/question.svg"),
	"safety-vest": () => import("~app/assets/images/awards/safety-vest.svg"),
	thinking: () => import("~app/assets/images/awards/thinking.svg"),
	tools: () => import("~app/assets/images/awards/tools.svg"),
	tree: () => import("~app/assets/images/awards/tree.svg"),
	treehouse: () => import("~app/assets/images/awards/treehouse.svg"),
	view: () => import("~app/assets/images/awards/view.svg"),
	weather: () => import("~app/assets/images/awards/weather.svg"),
	wireless: () => import("~app/assets/images/awards/wireless.svg"),
	work: () => import("~app/assets/images/awards/work.svg"),
	wrench: () => import("~app/assets/images/awards/wrench.svg"),
};

export type AwardIcon = keyof typeof AWARD_MAP;
