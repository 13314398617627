import { type QueryKey } from "@tanstack/react-query";

import { LeaderboardKey } from "~app/enums";
import { type ActivityTrack } from "~app/features/activities/api";
import {
	buildApiUrl,
	getCacheRemovalSet,
	getPreviousQueriesData,
	getSafeQueryData,
	handleNewCacheContentCreation,
	type OptimisticContext,
} from "~app/features/native/offline/utilities/optimistic-updates";
import { getProfileQueryKey, type ProfileResponse } from "~app/features/profile/api";
import { queryClient } from "~app/lib/queryClient";

import { getLeaderboardQueryKey } from ".";
import { getLeaderboardUrl } from "./leaderboard.api";
import { type LeaderboardPerson, type LeaderboardResponse } from "./leaderboard.schema";
import { getBaseLeaderboardQueryKey } from "./utilities";

const LEADERBOARD_KEYS = [LeaderboardKey.MONTHLY, LeaderboardKey.OVERALL] as const;

export const getApiUrl = (partialUrl: string, leaderboardKey: LeaderboardKey) => {
	const url = buildApiUrl(partialUrl);

	url.searchParams.append("leaderboardKey", leaderboardKey);

	return url.toString();
};

export const getOptimisticData = (queryKey: QueryKey, getUrl: () => string) => {
	const originalQueries = queryClient.getQueriesData<LeaderboardResponse>({
		exact: true,
		queryKey,
		type: "all",
	});

	const previousQueries = getPreviousQueriesData(originalQueries, getUrl);
	const safeQueries = getSafeQueryData(originalQueries, queryKey, []);
	const cacheRemovalData = getCacheRemovalSet(safeQueries.noExistingCacheKeys, getUrl);

	return {
		cacheRemovalData,
		data: safeQueries.safeData.flat()[1] as LeaderboardResponse,
		originalQueries,
		previousQueries,
		safeQueries,
	};
};

const sortLeaderboardData = (data: LeaderboardResponse) => {
	const newData = [...data];

	newData.sort((a, b) => Number(b.points) - Number(a.points));

	return newData;
};

export const getOptimisticLeaderboardQueryData = async <
	TContext extends OptimisticContext<LeaderboardResponse | undefined>,
>(
	activities: ActivityTrack[],
) => {
	const profile = queryClient.getQueryData<ProfileResponse>(getProfileQueryKey());

	const queryInfo = {
		onSuccessInvalidationKeys: [],
		previousQueries: [],
		toRemoveOnError: [],
	} as unknown as TContext;

	const validActivities = activities.filter(({ points }) => points !== 0);

	if (!validActivities.length || !profile) {
		return queryInfo;
	}

	await queryClient.cancelQueries({ queryKey: getBaseLeaderboardQueryKey(), type: "all" });

	for await (const leaderboardKey of LEADERBOARD_KEYS) {
		const leaderboardUrlGetter = () => getApiUrl(getLeaderboardUrl(), leaderboardKey);

		const leaderboard = getOptimisticData(getLeaderboardQueryKey(leaderboardKey), leaderboardUrlGetter);

		let mergedLeadersData: LeaderboardPerson[] = [];

		validActivities.forEach(({ points }) => {
			const tempCurrentLeaderData = leaderboard.data.find(({ isCurrentUser }) => isCurrentUser);

			const currentUserData = {
				avatarUrl: profile.avatarUrl,
				id: profile.id,
				isCurrentUser: true,
				name: profile.name,
				points: Number(tempCurrentLeaderData?.points ?? 0) + points,
				position: 1, // position will be recalculated
			} satisfies LeaderboardPerson;

			mergedLeadersData = [...leaderboard.data.filter(({ isCurrentUser }) => !isCurrentUser), currentUserData];
		});

		const sortedData = sortLeaderboardData(mergedLeadersData);

		const newLeaderData = sortedData.map((leader, index) => ({
			...leader,
			position: index + 1,
		}));

		const leaderboardOnSuccessInvalidationKeys = await handleNewCacheContentCreation(
			leaderboard.safeQueries.safeData,
			() => newLeaderData.slice(0, 3),
			leaderboardUrlGetter,
		);

		queryInfo.onSuccessInvalidationKeys.push(...leaderboardOnSuccessInvalidationKeys);
		queryInfo.previousQueries.push(...leaderboard.previousQueries);
		queryInfo.toRemoveOnError.push(...leaderboard.cacheRemovalData);
	}

	return queryInfo;
};
