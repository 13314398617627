import { buildUrl } from "~app/features/application/utilities";
import { wretch } from "~app/lib/external/wretch";
import { type AbortableRequestOptions } from "~app/types/application";

import { type SiteConfigResponse, siteConfigSchema } from "./site-config.schema";

const getUrl = () => buildUrl("config", 1);

const getSiteConfig = ({ signal }: AbortableRequestOptions): Promise<SiteConfigResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl())
		.json(siteConfigSchema.parseAsync);

type GetSiteConfigResponse = Awaited<ReturnType<typeof getSiteConfig>>;

export { getSiteConfig, type GetSiteConfigResponse };
