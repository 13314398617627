import "@mantine/carousel/styles.layer.css";
import "@mantine/core/styles.layer.css";
import "@mantine/dropzone/styles.layer.css";
import "@mantine/notifications/styles.layer.css";
import "@mantine/nprogress/styles.layer.css";
import "@evotix/napier-ui-common-components/styles.css";
import "@evotix/napier-ui-common-icons/styles.css";
import "@evotix/napier-ui-common-illustrations/styles.css";
import "./styles/application.scss";

import "@total-typescript/ts-reset";

import React, { lazy, Suspense } from "react";
import { FeatureFlags } from "@evotix/napier-ui-common-components";
import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { createRoot } from "react-dom/client";
import wretch from "wretch";

import { startAnalytics } from "./features/analytics";
import {
	globalConfig,
	globalConfigUrl,
	dynamicConfigSchema,
	ApplicationConfigProvider,
} from "./features/application/config";
import { applicationContext } from "./features/application/context";
import { initialiseNativeAppAuthentication } from "./features/native/authentication";
import { injectGainsightTag } from "./lib/external/gainsight/utilities";
import { loadTranslationResources } from "./translations/utilities/load-resources";

// eslint-disable-next-line jsdoc/require-jsdoc -- not needed for this function
async function enableMocking() {
	/**
	 * We don't want to enable mocking in production
	 */
	if (import.meta.env.PROD) return Promise.resolve();

	/**
	 * We don't want to enable the mocking unless were on the hosted url for this application
	 */
	if (globalConfig.get().endpointUrl !== "https://localhost:5174") return Promise.resolve();

	/**
	 * We lazy load the mock service worker here so that we don't attempt to load it in production
	 */
	const { worker } = await import("./mocks/browser");

	/**
	 * `worker.start()` returns a promise that resolves once the service worker is up and ready to intercept requests.
	 */
	return worker.start({ onUnhandledRequest: "bypass" });
}

/**
 * We lazy load the app here, so that we don't attempt to load/run any application code that this imports
 */
const Application = lazy(() => import("./App").then((module) => ({ default: module.App })));

wretch(globalConfigUrl)
	.get()
	.json(dynamicConfigSchema.parse)
	.then((config) => {
		globalConfig.set(config);

		injectGainsightTag(config.keys.gainsight);
	})
	.then(async () => {
		await enableMocking();

		const { initialiseI18n } = await import("./translations");

		initialiseI18n();

		if (globalConfig.isFeatureEnabled("enableTestLanguage")) {
			loadTranslationResources("pe-PE");
		}

		await import("./lib/external/logging/initLog");

		const bridgeInitialisationResponse = await NapierBridgeCommon.initialise();
		await initialiseNativeAppAuthentication(bridgeInitialisationResponse);
		applicationContext.platform = bridgeInitialisationResponse.platform;

		const config = globalConfig.get();

		createRoot(document.getElementById("root") as HTMLElement).render(
			<React.StrictMode>
				<ApplicationConfigProvider value={config}>
					<FeatureFlags.Context value={config.featureFlags}>
						<Suspense>
							<Application />
						</Suspense>
					</FeatureFlags.Context>
				</ApplicationConfigProvider>
			</React.StrictMode>,
		);

		startAnalytics();
	});
