import { buildUrl } from "~app/features/application/utilities";
import { wretch } from "~app/lib/external/wretch";

import { signOutResponseSchema } from "./sign-out.schema";

const getUrl = () => buildUrl("auth/sign-out", 1);

const postSignOut = () =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.post(null, getUrl())
		.text(signOutResponseSchema.parse);

type PostSignOutResponse = Awaited<ReturnType<typeof postSignOut>>;

export { postSignOut, type PostSignOutResponse };
