import { buildUrl } from "~app/features/application/utilities";
import { wretch } from "~app/lib/external/wretch";
import { type AbortableRequestOptions } from "~app/types/application";

import { type ResourceCategoryResponse, resourceCategorySchema } from "./category.schema";

const getUrl = (id: number) => buildUrl(`resource-category/${id}`, 1);

const getResourceCategory = (id: number, { signal }: AbortableRequestOptions): Promise<ResourceCategoryResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.get(getUrl(id))
		.json(resourceCategorySchema.parseAsync);

type GetResourceCategoryResponse = Awaited<ReturnType<typeof getResourceCategory>>;

export { getResourceCategory, getUrl, type GetResourceCategoryResponse };
