import { z } from "zod";

import { PostType } from "~app/enums";
import { dayjs } from "~app/lib/external/day-js";
import { cacheableMediaUrlSchema } from "~app/lib/schemas/cacheable-media-url";
import { titleSchema } from "~app/lib/schemas/title";

const bookmarkSchema = z.object({
	childIds: z.array(z.number()).nullable().catch([]),
	dateModified: z
		.string()
		.datetime({ offset: true })
		.catch(() => dayjs().toISOString()),
	description: z.string().nullable().catch(null),
	id: z.number(),
	thumbnailUrl: cacheableMediaUrlSchema(true).nullable().catch(null),
	title: titleSchema,
	type: z.nativeEnum(PostType),
});

export const bookmarksSchema = z.object({
	count: z.number(),
	items: z.array(bookmarkSchema),
});

export type Bookmark = z.infer<typeof bookmarkSchema>;

export type BookmarksResponse = z.infer<typeof bookmarksSchema>;
