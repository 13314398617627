import { z } from "zod";

import { DEFAULT_HEARTBEAT_INTERVAL } from "~app/features/application/constants";
import { PasswordStrength } from "~app/features/authentication/constants";
import { cacheableMediaUrlSchema } from "~app/lib/schemas/cacheable-media-url";
import { internalRouteSchema } from "~app/lib/schemas/internal-route-schema";
import { DEFAULT_LANGUAGE } from "~app/translations/constants";

const identityProviderSchema = z.object({
	authUrl: z.string().url().nullable().catch(null),
	id: z.string(),
	name: z.string(),
});

const siteConfigSchema = z
	.object({
		canSignup: z.boolean(),
		canUserEmailLogin: z.boolean(),
		configuredLinks: z
			.array(
				z
					.object({
						label: z.string().trim().nonempty(),
						url: z.string().url().or(internalRouteSchema),
					})
					.nullable()
					.catch(null),
			)
			.transform((links) => links?.filter((link) => link !== null))
			.nullable()
			.catch(null),
		hasThirdPartyIntegration: z.boolean(),
		identityProviders: z.array(identityProviderSchema).nullable(),
		language: z.object({
			default: z.string().nullable().catch(DEFAULT_LANGUAGE),
			supported: z.array(z.string().nullable()),
		}),
		logo: z.object({
			alt: z.string().nullable(),
			url: cacheableMediaUrlSchema(true).nullable().catch(null),
		}),
		name: z.string(),
		passwordStrength: z.nativeEnum(PasswordStrength).default(PasswordStrength.GOOD),
		privacyPolicy: z.boolean(),
		refreshTime: z.number().catch(DEFAULT_HEARTBEAT_INTERVAL),
		termsConditions: z.boolean(),
		theme: z.object({
			action: z.string().nullable(),
			backgroundUrl: cacheableMediaUrlSchema(true).nullable(),
			brand: z.string().nullable(),
		}),
		uploadRestrictions: z.object({
			accept: z.array(z.string()),
			maxSize: z.string(),
		}),
	})
	.passthrough();

type SiteConfigResponse = z.infer<typeof siteConfigSchema>;

export { siteConfigSchema, type SiteConfigResponse };
