import { z } from "zod";

import { PostType } from "~app/enums";

import { baseLearningGroupChild, baseLearningGroupSchema } from "../../shared.schema";

export const pathwaySchema = baseLearningGroupSchema.extend({
	children: z.array(
		baseLearningGroupChild.extend({
			type: z.literal(PostType.LEVEL),
		}),
	),
	type: z.literal(PostType.PATHWAY),
});

export type PathwayResponse = z.infer<typeof pathwaySchema>;
