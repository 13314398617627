import { createRoute, lazyRouteComponent, notFound, redirect, useNavigate } from "@tanstack/react-router";
import { WretchError } from "wretch/resolver";
import { z } from "zod";

import { ServerErrorCode } from "~app/enums";
import { applicationContext } from "~app/features/application/context";
import { getSiteConfigBaseQueryOptions } from "~app/features/site-config";
import { type Language } from "~app/translations/types";
import { switchLanguage } from "~app/translations/utilities";

import { rootRoute } from "../root";

import { dashboardRoute } from "./global";

export const tenantRoute = createRoute({
	beforeLoad: async ({ params, context, cause }) => {
		// Make sure the tenant is always lowercase
		if (params.tenant.toLowerCase() !== params.tenant) {
			throw redirect({
				params: {
					tenant: params.tenant.toLowerCase(),
				},
				replace: true,
				to: tenantRoute.to,
			});
		}

		// We only want to run the tenant route logic when we first enter the route match
		if (cause !== "enter") return;

		// Set the tenant in application context, so that we can get access to it outside on react scope
		applicationContext.tenant = params.tenant;

		try {
			const siteConfig = await context.queryClient.ensureQueryData(getSiteConfigBaseQueryOptions());

			await switchLanguage(siteConfig.language.default as Language);
		} catch (error) {
			if (error instanceof WretchError) {
				if (error.json.code === ServerErrorCode.INVALID_TENANT) {
					// As we a throwing a notFound in the beforeLoad, this will be picked up by the notFoundComponent on the rootRoute
					throw notFound({
						data: {
							wattCode: ServerErrorCode.INVALID_TENANT,
						},
					});
				}
			}
		}
	},
	component: lazyRouteComponent(() => import("~app/components/ThemedAppContainer"), "ThemedAppContainer"),
	getParentRoute: () => rootRoute,
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: tenantRoute.useParams(),
			to: dashboardRoute.to,
		});

		return null;
	},
	path: "$tenant",
	validateSearch: z.object({
		redirect: z.string().optional(),
	}),
});
